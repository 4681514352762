import React, {Component} from 'react'
import {graphql} from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/Layout'
import OurCompanyNavigationRow from '../components/OurCompanyNavigationRow'

export default class Integrations extends Component {
    render() {
        const logos = this.props.data.allFile.edges
        const logosEl = logos.map((logo, i) => {
            return (
                <div key={`logo-${i}`} className="img-wrapper">
                    <GatsbyImage image={logo.node.childImageSharp.gatsbyImageData} />
                </div>
            );
        })

        return (
            <Layout
                bodyClass="integrations"
                title="Integrated with FollowUpBoss, Contactually, MoxiWorks, BoomTown and more | HomeSpotter"
                description="HomeSpotter is integrated with over 50 CRM, publishing and marketing vendors and counting"
            >
                <div className="span-12-center">
                    <OurCompanyNavigationRow location={this.props.location}/>
                </div>

                <div className="ourcompany integrations">
                    <h1>We work well with others</h1>
                    <div className="integrations content">
                        <div>
                            <div className="section-header">Our History</div>
                            <p>
                                Since the very beginning, HomeSpotter has taken
                                pride in its ability to integrate with anyone
                                who will integrate with them … and even pulling
                                off integrations if the other vendor wasn’t
                                completely helpful or willing.
                            </p>

                            <p>
                                In our early days, our Connect product for
                                brokerages made it really straightforward for
                                agents and buyers to have all of their
                                information in one place, regardless of a
                                brokerage’s choice of website and CRM
                                platform(s).
                            </p>

                            <p>
                                We added to that foundation and pioneered deep
                                MLS integrations, making it easy for agents to
                                edit listings and update photos on the go in
                                their core MLS system, amongst many other
                                things.
                            </p>

                            <p>
                                Now, across our three solutions - Boost, Spacio,
                                and Connect - we have integrations with over 50+
                                vendors. We expect that number will continue to
                                grow as customers ask us to integrate what they
                                need next.
                            </p>
                        </div>
                        <div>
                            <div className="section-header">
                                Our Data Promises
                            </div>
                            <strong>We are pro integration</strong>
                            <p>
                                Have a vendor you want us to integrate with?
                                We’ll do our best, assuming they’re willing.
                                We’ll try and get creative with you if they’re
                                not so willing. Don’t see your vendor below?
                                Let’s talk.
                            </p>

                            <strong>No lock in </strong>
                            <p>
                                You’ll never feel stuck with our products
                                because you can’t get your data. Your data is
                                your data. Period.
                            </p>

                            <strong>
                                We put our client’s interests first{' '}
                            </strong>
                            <p>
                                If you’re paying close attention, you’ll notice
                                some vendor logos below that might seem
                                competitive to at least one of our three
                                solutions. We’ll integrate with anyone -
                                including otherwise competitive vendors - if
                                it’s in the best interest of our clients.
                            </p>

                            <strong>We safeguard your data</strong>
                            <p>
                                Your data is only as safe as its weakest link.
                                We take data security seriously.
                            </p>
                        </div>
                    </div>
                    <div className="integration-logos">
                        <div className="col-12">
                            <h2>Integrations in a cinch</h2>

                            <div className="integration__dots">
                                <div className="dot rotate-78"/>
                                <div className="dot rotate-134"/>
                                <div className="dot rotate-178"/>
                                <div className="dot rotate-129"/>
                            </div>

                            <div className="integration-logos-content span-12-center">
                                {logosEl}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export const query = graphql`{
  allFile(filter: {sourceInstanceName: {eq: "integration-logos"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(width: 200, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  }
}
`
